import jsPDF from 'jspdf'
import logo from './logo-img.png'
import RDSeal from './RdSeal_c.png'
import baseBoldFont from './chinaFont/baseBoldFont'
import baseFont from './chinaFont/baseFont'
import fangzhengfangsong from './chinaFont/fangzhengfangsong-normal'
import heiti from './chinaFont/heiti-normal'
import { multilineText, truncateText } from './utils'
import dayjs from 'dayjs';
class PI {
  constructor({ request, ossUrl }) {
    this.request = request
    this.ossUrl = ossUrl
    // 当前页面位置
    this.currentPage = 1
    // 总页面
    this.total = 1
    // 开头x轴
    this.baseX = 15
    // 记录零件列表中每一项x轴的位置
    this.partsTh = [this.baseX + 2, 70, 95, 145, 160, 180]
    // 记录模具列表中每一项x轴的位置
    // this.moldTh = [this.baseX + 2, 40, 75, 145, 160, 180]
    // 记录页面所在Y轴
    this.currentY = 0
    //
    this.ROW_HEIGHT = 10
    this.maxPageY = 260
    this.doc = new jsPDF()
  }
  // 字符串分割指定长度的数组
  splitStr(str, length) {
    const arr = []

    let index = 0
    while (index < str.length) {
      arr.push(str.slice(index, (index += length)))
    }

    return arr
  }

  getDateTime(time) {
    return dayjs(time * 1000).format('YYYY-MM-DD');
  }


  getImgCanvas(url, bg = '#ffffff') {
    return new Promise(resolve => {
      const img = new Image()
      img.src = url
      img.style.backgroundColor = bg

      img.onload = e => {
        const imgWidth = img.width
        const imgHeight = img.height

        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d')
        canvas.width = imgWidth
        canvas.height = imgHeight
        ctx.fillStyle = bg
        ctx.fillRect(0, 0, canvas.width, canvas.height)
        ctx.drawImage(img, 0, 0, imgWidth, imgHeight)
        resolve({
          img: canvas.toDataURL('image/jpeg'),
          msg: {
            ratio: imgWidth / imgHeight,
            imgWidth,
            imgHeight
          }
        })
      }
    })
  }

  // 绘制实线的方法
  drawSolidLine(currentY, lineWidth = 0.3, paddingY = 8) {
    const endX = this.doc.internal.pageSize.width - 15; // 右侧间距

    // 计算线的起始和结束位置
    const lineStartY = currentY + paddingY;
    const lineEndY = lineStartY + lineWidth;

    // 设置线宽并绘制
    this.doc.setLineWidth(lineWidth);
    this.doc.setDrawColor(238, 238, 238); // 设置颜色为 #EEEEEE

    this.doc.line(this.baseX, lineStartY, endX, lineStartY);

    // 返回下一个绘制内容的起点Y值
    return lineEndY + paddingY;
  }

  // 写顶部相关信息
  async writeTop() {
    const { order: { is_batches, batch_no }, order_no } = this.data;
    try {
      const { img } = await this.getImgCanvas(logo)
      this.doc.addImage(img, this.baseX, 14, 40, 14, 'logo', 'NONE')
      this.doc.setTextColor(0, 0, 0)
      this.doc.setFont('BaseBoldFont')
      this.doc.setFontSize(16)
      this.doc.text('COMMERCIAL INVOICE', 191.5, 22, {
        align: 'right',
        charSpace: 0.4
      })
      this.doc.setFont('BaseFont')
      this.doc.setFontSize(8)
      let showInvoiceNo = order_no;
      if(is_batches && batch_no) {
        showInvoiceNo += `-Batch ${batch_no}`
      }
      this.doc.text(`Invoice No: ${showInvoiceNo}`, 195, 27, null, null, 'right')

      return Promise.resolve()
    } catch (error) {}
  }
  writeOrderInfoMsg() {
    const { order, address } = this.data;
    const currentX = this.baseX;
    let currentY = 45;
    const spacingX = 65; // 字段名与字段值的间隔
    const rowSpacingY = 6; // 每行间距
    const topFields = [
      { label: 'Date:', value: 'order_time' },
      { label: 'Country of Origin:', value: 'China' },
      { label: 'INCO Terms:', value: 'grand_total_type' },
      { label: 'Tax No. :', value: 'tax_no' },
      { label: 'PO No.:', value: "po_no" },
    ]
    this.doc.setFontSize(8)
    // 渲染每行3个字段
    topFields.forEach((field, index) => {
      const rowIndex = Math.floor(index / 3); // 当前行
      const columnIndex = index % 3;          // 当前列

      const xPos = currentX + columnIndex * spacingX;
      const yPos = currentY + rowIndex * rowSpacingY;

      // 字段名
      this.doc.setFont('BaseBoldFont');
      this.doc.text(field.label, xPos, yPos);
      
      // 字段值
      this.doc.setFont('BaseFont');
      if(field.label == 'Country of Origin:') {
        this.doc.text('China', xPos + 30, yPos, null, null, 'left');
      }else if(field.label == 'PO No.:') {
        this.doc.text(address.po_no, xPos + 30, yPos, null, null, 'left');
      }else if(field.label == 'Date:') {
        this.doc.text(address.invoice_date || '', xPos + 12, yPos, null, null, 'left');
      }else if(field.label == 'Tax No. :') {
        multilineText(this.doc, order[field.value] || '\\' , xPos + 12, yPos, 40, 99, 3);
      }else {
        if(!order.grand_total_type) {
          order.grand_total_type = 'DAP'
        }
        this.doc.text(order[field.value] || '\\', xPos + 30, yPos, null, null, 'left');
      }
    });
    currentY = currentY + rowSpacingY;
    // =========画线==========
    currentY = this.drawSolidLine(currentY);

    const cFieldsData = [
      {
        head: 'Consignee',
        fields: [
          { label: 'To:', value: 'to' },
          { label: 'Ship To:', value: 'ship_to' },
          { label: 'Email:', value: 'email' },
          { label: 'Atten:', value: 'atten' },
          { label: 'Tel:', value: 'tel' },
        ]
      },
      {
        head: 'Notify Party',
        fields: [
          { label: 'To:', value: 'bill_company_name' },
          { label: 'Bill To:', value: 'Bill To' },
          { label: 'Email:', value: 'bill_email' },
          { label: 'Atten:', value: 'Atten' },
          { label: 'Tel:', value: 'bill_phone' },
        ]
      }
    ];

    const pageWidth = this.doc.internal.pageSize.getWidth();
    const columnWidth = (pageWidth - this.baseX * 2) / 2; // 计算每列宽度
    const columnGap = 10; // 两列之间的间距
    cFieldsData.forEach((column, index) => {
      const xPosition = index === 0 ? this.baseX : this.baseX + columnWidth + columnGap;
      this.doc.setFontSize(10);
      this.doc.setFont('BaseBoldFont');
      this.doc.text(column.head, xPosition, currentY);

      this.doc.setFontSize(8);
      let fieldY = currentY + 5;
      column.fields.forEach(item => {
        if(column.head == 'Notify Party') {
          if (item.label == 'Bill To:') {
            const addressParts = [order.bill_address, order.bill_city, order.bill_province, order.bill_country, order.bill_zipcode].filter(Boolean).join(', ');
            order[item.value] = addressParts;
          }else if(item.label == 'Atten:') {
            order[item.value] = order.bill_first_name + order.bill_last_name
          }
        }
        // 🔥 加粗 label
        this.doc.setFont('BaseBoldFont');
        this.doc.text(`${item.label}`, xPosition, fieldY);
        // 🔹 常规字体渲染 value
        this.doc.setFont('BaseFont');
        const maxWidth = columnWidth - 40; // 控制每行最大宽度
        // 处理value内容自动换行+超出两行显示省略号
        const showValue = column.head == 'Consignee'? address[item.value] : order[item.value]
        let truncatedValue = showValue? truncateText(this.doc, showValue, maxWidth) : '\\';
        if(['To:', 'Email:'].includes(item.label) && truncatedValue.length > 40 && (!truncatedValue.includes("\n") || truncatedValue.startsWith("\n"))) {
          const chunks = truncatedValue.match(new RegExp(`.{1,30}`, 'g'));
          // 拼接换行符
          truncatedValue = chunks.join('\n');
        } 
        this.doc.text(truncatedValue, xPosition + 30, fieldY);
        fieldY += ['Ship To:', 'Bill To:'].includes(item.label)? 10 : 6; // 行间距
      });
    });

    currentY += 30
    currentY = this.drawSolidLine(currentY);
    const bFieldData = [
      { label: 'Manufacturer:', value: 'Shenzhen Rapid Direct Co., Ltd.' },
      { label: 'Email:', value: 'sale_email' },
      { label: 'Contact:', value: 'sale_contact' },
      { label: 'Tel:', value: 'sale_tel' },
      { label: 'Waybill No.:', value: '' },
      { label: 'Address:', value: 'Building A12, Haosi Industrial Park, Nanpu Road, Xinqiao Street, Bao an District, Shenzhen, China.518104' },
    ]
    let leftY = currentY;
    let rightY = currentY;
    bFieldData.forEach((item, index) => {
      const isLeftColumn = index % 2 === 0;
      const xPosition = isLeftColumn
            ? this.baseX // 左侧列
            : this.baseX + columnWidth + columnGap; // 右侧列

      const yPosition = isLeftColumn ? leftY : rightY;
      // 🔥 加粗 label
      this.doc.setFont('BaseBoldFont');
      this.doc.text(`${item.label}`, xPosition, yPosition);
      
      this.doc.setFont('BaseFont');
      const maxWidth = columnWidth - 40; // 控制每行最大宽度
      // 🔹 常规字体渲染 value
      if(item.label == 'Manufacturer:') {
        this.doc.text(item.value, xPosition + 30, yPosition);
      }else if(item.label == 'Address:') {
        // 处理value内容自动换行+超出两行显示省略号
        const truncatedValue = truncateText(this.doc, item.value, maxWidth, 3);
        this.doc.text(truncatedValue, xPosition + 30, yPosition);
      }else if(item.label == 'Waybill No.:') {
        const waybillNoStr = order.deliver_list.map(dItem => {
          return order.is_batches? `batch ${dItem.batch_no}:${dItem.deliver_no}` : dItem.deliver_no;
        }).join('\\');
        // 处理value内容自动换行+超出两行显示省略号
        const truncatedValue = truncateText(this.doc, waybillNoStr, maxWidth, 3);
        this.doc.text(truncatedValue, xPosition + 30, yPosition);
      }else {
        this.doc.text(address[item.value] || '', xPosition + 30, yPosition);
      }
      // 更新对应列的 Y 坐标
      if (isLeftColumn) {
          leftY += 6;
      } else {
          rightY += 6;
      }
    });

    currentY = Math.max(leftY, rightY);
    currentY = this.drawSolidLine(currentY);
    return currentY;
  }

  writeMoldTbHeader() {
    const baseY = this.currentY
    this.doc.setFontSize(7)
    this.doc.setTextColor(116, 116, 116)
    this.doc.setFont('BaseBoldFont')
    this.doc.text('Mold No.', this.partsTh[0], baseY, null, null, 'left')
    this.doc.text('HTS code', this.partsTh[1], baseY, null, null, 'left')
    this.doc.text('Description', this.partsTh[2], baseY, null, null, 'left')
    this.doc.text('Quantity', this.partsTh[3], baseY, null, null, 'left')
    this.doc.text('Unit Price', this.partsTh[4], baseY, null, null, 'left')
    this.doc.text('Amount', this.partsTh[5], baseY, null, null, 'left')
  }
  writeBlockBg(y, h, cl) {
    let color = [250, 250, 250]
    if (cl) {
      var r = parseInt(cl.substring(1, 3), 16)
      var g = parseInt(cl.substring(3, 5), 16)
      var b = parseInt(cl.substring(5, 7), 16)
      color = [r, g, b]
    }
    this.doc.setDrawColor(255, 255, 255)
    this.doc.setFillColor(...color)
    this.doc.rect(this.baseX, y, 180, h > 14 ? h : 14, 'FD')
  }
  writePartTbHeader() {
    const baseY = this.currentY
    this.doc.setFontSize(7)
    this.doc.setFont('BaseBoldFont')
    this.doc.setTextColor(116, 116, 116)
    this.doc.text('Part No.', this.partsTh[0], baseY, null, null, 'left')
    this.doc.text('HTS code', this.partsTh[1], baseY, null, null, 'left')
    this.doc.text('Description', this.partsTh[2], baseY, null, null, 'left')
    this.doc.text('Quantity', this.partsTh[3], baseY, null, null, 'left')
    this.doc.text('Unit Price', this.partsTh[4], baseY, null, null, 'left')
    this.doc.text('Amount', this.partsTh[5], baseY, null, null, 'left')
  }
  async writePartBlockBg(index, part) {
    // 这里是要画背景矩形，但是由于不知道零件高度多少，所以逻辑是先画零件内容，得到高度后，再根据返回的零件数据的最高y得到矩形的高度
    let originY = this.currentY
    let maxY = 0
    const partRes = this.writePart(
      index,
      part,
      this.currentY,
      index % 2 === 0 ? 'rgb(250, 250, 250)' : '#ffffff'
    )
    maxY = partRes
    if (maxY > this.maxPageY) {
      // 如果当前零件放不下了，那么就要新建页面
      // 把原本的零件用白色矩形隐藏起啦哎
      this.writeBlockBg(this.currentY, maxY - originY + 2, '#ffffff')
      // 添加新的页面
      await this.appendNewPage()
      // 重新再画当前的零件
      const res = await this.writePart(
        index,
        part,
        this.currentY,
        index % 2 === 0 ? 'rgb(250, 250, 250)' : '#ffffff'
      )
      // 重新赋值
      maxY = res.maxY
      originY = this.currentY
    }
    if (index % 2 === 0) {
      this.writeBlockBg(this.currentY, maxY - originY + 2)
    } else {
      this.writeBlockBg(this.currentY, maxY - originY + 2, '#ffffff')
    }
    this.currentY = originY
  }
  writePart(index, part, y, bg) {
    this.doc.setFont('BaseFont')
    this.doc.setFontSize(5)
    this.doc.setTextColor(0, 0, 0)
    let addOneY = 0;
    let addTwoY = 0;
    if(this.data.order.process_type == 1) {
      addOneY = y + 6
      addTwoY = y + 4;
    } else {
      addOneY = y + 7
      addTwoY = y + 5;
    }
    this.doc.text(index + 1 + '', this.partsTh[0],addOneY, null, null, 'left')
    this.doc.text(part.name, this.partsTh[0] + 5,addOneY, null, null, 'left')
    this.doc.setFontSize(6)
    this.doc.text(part.hts_code || '\\', this.partsTh[1],addOneY, null, null, 'left')
    const description = part.product_en_name? `${part.product_en_name}\nUse For: engineering development and testing` : `Material: ${part.mat_category_name}\nUse For: engineering development and testing`
    multilineText(
      this.doc,
      description,
      this.partsTh[2],
      addTwoY,
      80,
      99,
      3
    )


    this.doc.setFontSize(6)
    this.doc.text(part.num + '', this.partsTh[3],addOneY, null, null, 'left')

    this.doc.text(
      part.ori_unit_price,
      this.partsTh[4],
      addOneY,
      null,
      null,
      'left'
    )
    this.doc.text(part.ori_price, this.partsTh[5],addOneY, null, null, 'left')

    return  y + 8
  }
  writeMold(mold, y) {
    this.doc.setFontSize(5)
    this.doc.setFont('BaseFont')
    this.doc.setTextColor(0, 0, 0)
    this.doc.text(mold.mould_no, this.partsTh[0], y + 8, null, null, 'left')
    this.doc.text(mold.hts_code || '\\', this.partsTh[1], y + 8, null, null, 'left')
    const description = mold.product_en_name? `${mold.product_en_name}\nUse For: engineering development and testing` : `Material: ${mold.mould_material}\nUse For: engineering development and testing`
    multilineText(
      this.doc,
      description,
      this.partsTh[2],
      y + 6,
      80,
      99,
      3
    )
    let mouldNum = null;
    let mouldPrice = null;
    let totalMouldPrice = null;
    if(this.data.order.is_return) {
      mouldNum = '-'
      mouldPrice = '-'
      totalMouldPrice = '-';
    }else {
      mouldNum = mold.mould_num
      mouldPrice = mold.mould_price
      totalMouldPrice = mold.mould_total_price
    }
    this.doc.text(
      mouldNum + '',
      this.partsTh[3],
      y + 8,
      null,
      null,
      'left'
    )
    this.doc.text(
      mouldPrice + '',
      this.partsTh[4],
      y + 8,
      null,
      null,
      'left'
    )
    this.doc.text(
      totalMouldPrice + '',
      this.partsTh[5],
      y + 8,
      null,
      null,
      'left'
    )
  }
  // 写底部页脚
  writeFooter(cb) {
    this.doc.setLineWidth(0.5)
    this.doc.setDrawColor(0, 0, 0)
    this.doc.line(195, 285, this.baseX, 285)

    this.doc.setFontSize(10)
    this.doc.setFont('BaseFont')
    this.doc.setTextColor(0, 0, 0)

    this.doc.textWithLink('www.rapiddirect.com', this.baseX, 290, {
      url: 'https://www.rapiddirect.com/'
    })

    this.doc.text(
      `Page:${this.currentPage}/${this.total}`,
      195,
      290,
      null,
      null,
      'right'
    )

    if (this.currentPage !== 1) {
      setTimeout(() => {
        // 这里需要写递归不断向上写页脚
        this.currentPage -= 1
        this.doc.movePage(this.currentPage, this.currentPage + 1)
        this.writeFooter(cb)
      }, 100)
    } else {
      // 这里代表已经写完页脚，需要把位置改为原本的位置
      this.currentPage = this.total
      this.doc.movePage(1, this.total)
      cb()
    }
  }
  async writeData() {
    if ((this.data.order.process_type === 2 || this.data.order.is_return) && this.data.mould_data.length) {
      // 注塑
      for (let i = 0; i < this.data.mould_data.length; i++) {
        const item = this.data.mould_data[i]
        if (this.currentY > this.maxPageY) {
          await this.appendNewPage()
        }
        if (i !== 0) {
          this.currentY += 10
        }
        this.writeMoldTbHeader()
        this.currentY += 3
        this.writeBlockBg(this.currentY)
        this.writeMold(item, this.currentY)
        this.currentY += 23
        const part = this.data.order.order_parts_list.filter(
          r => r.mould_id === item.id
        )
        console.log(part,'partpart=part');
        for (let j = 0; j < part.length; j++) {
          const r = part[j]
          const k = j
          if (this.currentY > this.maxPageY) {
            await this.appendNewPage()
          }
          if (k === 0) {
            this.writePartTbHeader()
          }
          this.currentY += 3
          if (k % 2 === 0) {
            this.writeBlockBg(this.currentY)
          }
          this.doc.setFont('BaseFont')
          await this.writePartBlockBg(k, r)
          const maxY = this.writePart(
            k,
            r,
            this.currentY,
            k % 2 === 0 ? 'rgb(250, 250, 250)' : '#ffffff'
          )
          this.currentY = maxY + 2
          if (this.currentY > this.maxPageY) {
            await this.appendNewPage()
          }
        }
        this.currentY += 8
      }
    } else {
      // 非注塑
      this.writePartTbHeader()
      this.currentY += 3
      for (let i = 0; i < this.data.order.order_parts_list.length; i++) {
        const index = i
        const item = this.data.order.order_parts_list[i]
        await this.writePartBlockBg(index, item)
        this.doc.setFont('BaseFont')
        const res = await this.writePart(
          index,
          item,
          this.currentY,
          index % 2 === 0 ? 'rgb(250, 250, 250)' : '#ffffff'
        )
        this.currentY = res + 2
        if (this.currentY > this.maxPageY) {
          await this.appendNewPage()
        }
      }
      // this.data.order.order_parts_list.forEach((item, index) => {
      // })
    }
    return Promise.resolve()
  }

  async writeMoneyMsg() {
    this.doc.setFont('BaseFont')
    const { order: { total_other, usd_freight, parts_amount, process_type, total_price, order_parts_list, discount_money, return_amount, total_mould_price }, mould_data, other_list, not_freight } = this.data;
    const totalPartNum = order_parts_list.reduce((sum, item) => sum + item.num, 0);
    let totalMoldNum = 0;
    if (this.currentY > this.maxPageY) {
      await this.appendNewPage()
    }
    this.doc.setFontSize(7)
    this.currentY += 6;
    if(process_type === 2) {
      this.currentY += 6;
      this.doc.text('Part production', this.baseX + 2, this.currentY, null, null, 'left')
      this.doc.text(totalPartNum + '', this.partsTh[3], this.currentY, null, null, 'right')
      this.doc.text('$ ' + parts_amount, this.partsTh[5] + 10, this.currentY, null, null,'right')

      totalMoldNum = mould_data.reduce((sum, item) => sum + item.mould_num, 0);
      if(mould_data.length) {
        this.currentY += 6;
        this.doc.text('Tooling', this.baseX + 2, this.currentY, null, null, 'left')
        this.doc.text(totalMoldNum + '', this.partsTh[3], this.currentY, null, null, 'right')
        this.doc.text('$ ' + total_mould_price, this.partsTh[5] + 10, this.currentY, null, null,'right')
      }
      this.currentY += 6;
    }
    // 是否显示运费项
    if(!+not_freight) {
      this.doc.text('Freight Cost', this.baseX + 2, this.currentY, null, null, 'left')
      this.doc.text(
        '$ ' + usd_freight + '',
        this.partsTh[5] + 10,
        this.currentY,
        null,
        null,
        'right'
      )
    }

    // 是否有优惠金额
    if(+discount_money) {
      this.currentY += 6;
      this.doc.text('Total discount', this.baseX + 2, this.currentY, null, null, 'left')
      this.doc.text('-$ ' + discount_money, this.partsTh[5] + 10, this.currentY, null, null,'right')
    }

    // 是否有其他追加费用
    if(Number(total_other) && other_list.length) {
      other_list.forEach(({ name, amount }) => {
        this.currentY += 6;
        const showName = `Other (${name})`
        this.doc.text(showName, this.baseX + 2, this.currentY, null, null, 'left')
        this.doc.text(amount + '', this.partsTh[5] + 10, this.currentY, null, null,'right')
      })
    }

    // 是否有退款金额
    if(+return_amount) {
      this.currentY += 6;
      this.doc.text('Refunded', this.baseX + 2, this.currentY, null, null, 'left')
      this.doc.text(
        '-$ ' + return_amount + '',
        this.partsTh[5] + 10,
        this.currentY,
        null,
        null,
        'right'
      )
    }

    this.doc.setFontSize(9)
    this.doc.setFont('BaseBoldFont')
    this.doc.setTextColor(234, 84, 63);
    this.currentY += 8;
    this.doc.text('TOTAL', this.baseX + 2, this.currentY, null, null, 'left')
    const totalQuantity = process_type == 1? totalPartNum : totalPartNum + totalMoldNum;
    this.doc.text(totalQuantity + '', this.partsTh[3], this.currentY, null, null, 'right')
    this.doc.text('$ ' + total_price, this.partsTh[5] + 10, this.currentY, null, null, 'right')
    // if (process_type === 2) {
    // } else {
    //   this.doc.text(
    //     '$ ' + parts_amount + '',
    //     this.partsTh[5] + 10,
    //     this.currentY,
    //     null,
    //     null,
    //     'right'
    //   )
    // }

    this.currentY += 8
    this.doc.setFont('BaseFont')
    this.doc.setFontSize(7)
    this.doc.setTextColor(0, 0, 0);
    this.doc.text(
      'Remarks:We here state that no iron/steel components of Russian origin are contained in the products.', 
      this.baseX + 2, 
      this.currentY, null, null, 'left')
  }
  async writeOrderMsg() {
    if (this.currentY + 20 > this.maxPageY) {
      await this.appendNewPage()
    }
    this.doc.setFontSize(8)
    this.doc.setFont('BaseBoldFont');
    this.doc.text('Stamp of Manufacturer:', this.baseX + 2, this.currentY + 30, null, null, 'left')
    const formattedDate = dayjs().format('YYYY/MM/DD');
    this.doc.text(`Date: ${formattedDate}`, this.partsTh[4], this.currentY + 30, null, null, 'left')
    const { img } = await this.getImgCanvas(RDSeal)
    this.doc.addImage(img, 53, this.currentY + 5, 40, 40, 'RDSeal', 'NONE')

    return Promise.resolve()
  }
  // 添加新页面，并把y轴位置初始化
  async appendNewPage() {
    this.doc.addPage('a4', 'p')
    await this.writeTop()
    this.currentY = 50
    this.currentPage += 1
    this.total += 1
    return Promise.resolve()
  }
  //  二进制转base64
  blobToDataURI(blob) {
    const reader = new FileReader()
    return new Promise(resolve => {
      reader.onload = function(e) {
        resolve(reader.result)
      }
      reader.readAsDataURL(blob)
    })
  }
  // 公共初始化方法
  initializeFonts() {
    this.doc.addFileToVFS('BaseBoldFont.ttf', baseBoldFont)
    this.doc.addFont('BaseBoldFont.ttf', 'BaseBoldFont', 'normal')
    this.doc.addFileToVFS('BaseFont.ttf', baseFont)
    this.doc.addFont('BaseFont.ttf', 'BaseFont', 'normal')
    this.doc.addFileToVFS('fangzhengfangsong', fangzhengfangsong)
    this.doc.addFont('fangzhengfangsong', 'fangzhengfangsong', 'normal')
    this.doc.addFileToVFS('heiti', heiti)
    this.doc.addFont('heiti', 'heiti', 'normal')
  }
  // 处理流程类型
  handleProcessType() {
    if(this.data.order.process_type == 2 && this.data.order.is_return) {
      this.data.order.process_type = 1
    }
  }
  // 公共文档内容生成
  async writeDocumentContents() {
    await this.writeTop()
    this.currentY = this.writeOrderInfoMsg();
    await this.writeData()
    await this.writeMoneyMsg()
    console.log(33333333333,'=======');
    await this.writeOrderMsg()
  }
  // 初始化订单数据
  setupOrderData(data) {
    this.data = data;
    this.handleProcessType();
  }
  async run() {
    const { data } = await this.request
    this.initializeFonts();
    this.setupOrderData(data);
    await this.writeDocumentContents()
    return new Promise(resolve => {
      this.writeFooter(() => {
        this.doc.save(`Commercial Invoice - ${this.data.order.order_no}.pdf`)
        resolve();
      })
    });
  }
}

export default PI

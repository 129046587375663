import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
dayjs.extend(utc)
dayjs.extend(timezone)

export const formatPrice = price => {
  if (!price && price !== 0) return
  // 千分位分隔符
  return Number(price).toLocaleString()
}

export const newFormatPrice = price => {
  if (!price) return
  // 将数字放大 100 倍，使用 Math.floor 截断小数，避免四舍五入
  const factor = 100
  const truncatedNum = Math.floor(price * factor) / factor

  // 使用 Intl.NumberFormat 格式化为千分位并确保两位小数
  return truncatedNum.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  })
}

// 根据文件后缀获取类型
export const getFileType = suffix => {
  let type = ''
  if (['step', 'stp', 'stl'].includes(suffix)) {
    type = 1
  } else if (suffix === 'pdf') {
    type = 2
  } else if (['xls', 'xlsx', 'csv'].includes(suffix)) {
    type = 3
  } else if (['doc', 'docx'].includes(suffix)) {
    type = 4
  } else if (['ppt', 'pptx'].includes(suffix)) {
    type = 5
  } else if (['png', 'jpg', 'jpeg', 'bmp', 'gif', 'webp', 'psd', 'svg', 'tiff'].includes(suffix)) {
    type = 6
  } else {
    type = 7
  }
  return type
}

// 比较时间戳相距时间
export function diffTime(startTime = +new Date(), endTime = +new Date()) {
  if (startTime >= endTime) {
    return {
      day: '00',
      hour: '00',
      minute: '00',
      second: '00',
      ms: '00',
      total_hour: '00',
      stopFlag: true
    }
  }

  const time = endTime - startTime

  // 毫秒数
  const s = 1000
  const m = s * 60
  const h = m * 60
  const d = h * 24

  let day = Math.floor(time / d)
  let hour = Math.floor((time % d) / h)
  let minute = Math.floor((time % h) / m)
  let second = Math.floor((time % m) / s)
  let ms = Math.floor((time % 1000) / 10)
  let totalHour = day <= 0 ? hour : day * 24 + hour

  if (day < 10) {
    day = '0' + day
  }
  if (hour < 10) {
    hour = '0' + hour
  }
  if (minute < 10) {
    minute = '0' + minute
  }
  if (second < 10) {
    second = '0' + second
  }
  if (ms < 10) {
    ms = '0' + ms
  }
  if (totalHour < 10) {
    totalHour = '0' + totalHour
  }

  return {
    day,
    hour,
    minute,
    second,
    ms,
    totalHour
  }
}
export const px2rem = px => {
  let width = document.body.getBoundingClientRect().width
  let rem = window.rem
  if (width >= 1920) {
    rem = 192
  }
  let val = parseFloat(px) / rem
  if (typeof px === 'string' && px.match(/px$/)) {
    val += 'rem'
  }
  return val
}

export function generateRandomString(length) {
  let result = ''
  let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length)
    result += characters.charAt(randomIndex)
    characters = characters.slice(0, randomIndex) + characters.slice(randomIndex + 1)
  }

  return result
}

export const sleep = time => new Promise(resolve => setTimeout(resolve, time))
// 防抖
export function debounce(fn, delay = 300) {
  let timeout
  return function() {
    clearTimeout(timeout)
    timeout = setTimeout(() => {
      fn.apply(this, arguments)
    }, delay)
  }
}

//Base64格式的图片转换为URL地址
export function dataURItoBlob(base64Url) {
  var byteString = atob(base64Url.split(',')[1])
  var mimeString = base64Url
    .split(',')[0]
    .split(':')[1]
    .split(';')[0]
  var ab = new ArrayBuffer(byteString.length)
  var ia = new Uint8Array(ab)
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }
  const blob = new Blob([ab], { type: mimeString })
  return URL.createObjectURL(blob)
}
// 创建a标签下载
export function downFile(href, name) {
  var downloadElement = document.createElement('a')
  downloadElement.href = href
  downloadElement.target = '_blank'
  downloadElement.download = name // 下载后文件名
  document.body.appendChild(downloadElement)
  downloadElement.click() // 点击下载
  document.body.removeChild(downloadElement) // 下载完成移除元素
}
//字符串截取最后.之后的字符
export function getLastStrValue(val, string = '.') {
  const spliceLength = val.lastIndexOf(string)
  return val.slice(spliceLength + 1)
}
export const CacheUtils = {
  // 存储请求接口地址以及请求体和取消函数之间的映射关系
  cache: {},
  // 根据提供的键名 key 取消对应的请求，若未提供则取消全部请求
  clearCache: function(key) {
    if (key) {
      const cancel = this.cache[key]
      if (cancel && typeof cancel === 'function') {
        cancel()
        delete this.cache[key]
      }
      return
    }
    Object.keys(this.cache).forEach(cacheKey => {
      const cancel = this.cache[cacheKey]
      cancel()
      delete this.cache[cacheKey]
    })
  }
}
export const isInputEmpty = function(input) {
  // 判断输入是否为空值
  if (!input) return true
  if (!input.trim()) {
    return true // 输入框全是空格或空字符串
  }

  // 判断输入是否全是换行符
  const lines = input.split('\n')
  for (let line of lines) {
    if (line.trim()) {
      return false // 输入框有非空行
    }
  }

  return true // 输入框全是换行符
}

// 判断本地时间是否处在夏令时
export function isAtDSTime() {
  let d1 = new Date(2024, 0, 1)
  let d2 = new Date(dayjs().format('YYYY,MM,DD'))
  if (d1.getTimezoneOffset() != d2.getTimezoneOffset()) {
    return true
  } else {
    return false
  }
}
// countAmount
export function countAmount(arr) {
  if (!Array.isArray(arr)) return
  let total = 0
  arr.forEach(item => {
    total += item * 100
  })
  return total / 100
}

// 时间格式化
export function transTimeType(date, type) {
  if (date && date.length <= 6) {
    return date
  }
  // let currentGuess = dayjs.tz.guess() //当前时区
  // 北京时间减去8小时，获取本机时间与格林威治时间时间差，再作加减法得到本机时间；
  let time
  if (type == 1) {
    time = dayjs(date)
      .subtract(8, 'hour')
      .add(-new Date().getTimezoneOffset(), 'minute')
      .format('YYYY-MM-DD HH:mm')
  } else {
    time = dayjs(date)
      .subtract(8, 'hour')
      .add(-new Date().getTimezoneOffset(), 'minute')
      .format('HH:mm')
  }
  return time
}

// 验证是否为新用户 （注册时间为2025-01-01以后的）
export function checkNewUser() {
  let regTime = localStorage.getItem('regTime')
  let deadline = new Date('2025-01-01').getTime() / 1000
  return regTime > deadline
}

// 默认图片
export function getFile(fileUrl) {
  if (!fileUrl || fileUrl == '/') return require('assets/images/3dloading.png')
  const reg = /^data:/
  if (fileUrl !== require('assets/images/3dloading.png') && !reg.test(fileUrl)) {
    return process.env.baseOss + fileUrl
  } else {
    return fileUrl
  }
}

export function getTimeStamp(value) {
  return new Date(value).getTime() / 1000
}

import Vue from 'vue'
import dayjs from 'dayjs'
import relativeTIme from 'dayjs/plugin/relativeTime'
import updateLocale from 'dayjs/plugin/updateLocale'
dayjs.extend(relativeTIme)
dayjs.extend(updateLocale)
dayjs.updateLocale('en', {
  relativeTime: {
    future: '%s',
    past: '%s',
    s: 'Now',
    m: '1 min. ago',
    mm: '%d mins. ago',
    h: '1 hr. ago',
    hh: '%d hrs. ago',
    d: '1 day ago',
    dd: '%d days ago',
    M: '1 mo. ago',
    MM: '%d mos. ago',
    y: '1 yr. ago',
    yy: '%d yrs. ago'
  }
})
Vue.filter('format_time', time => {
  if (!time) return ''
  let utc = dayjs()
    .utc()
    .format('YYYY-MM-DD HH:mm:ss')
  let formTime = dayjs(time)
    .subtract(8, 'hour')
    .format('YYYY-MM-DD HH:mm:ss')
  let t = dayjs(formTime).from(utc)
  return t
})
Vue.filter('formatDate_en', (value, type) => {
  if (!value) return ''
  var dt = new Date(value * 1000)
  if (type == 'mm:dd' || type == 'date') {
    dt = new Date(value)
  }
  var mArr = new Array(
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Spt',
    'Oct',
    'Nov',
    'Dec'
  )
  const m = dt.getMonth()
  const y = dt.getFullYear()
  const d = dt.getDate()
  if (type == 'mm:dd') {
    return mArr[m] + ' ' + d
  }
  return mArr[m] + ' ' + d + ',' + y + '(GMT+8)'
})

Vue.filter('formatDate', (date, fmt) => {
  if (!fmt) {
    fmt = 'yyyy-MM-dd hh:mm:ss'
  }
  date = new Date(date)
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(
      RegExp.$1,
      (date.getFullYear() + '').substr(4 - RegExp.$1.length)
    )
  }
  let o = {
    'M+': date.getMonth() + 1,
    'd+': date.getDate(),
    'h+': date.getHours(),
    'm+': date.getMinutes(),
    's+': date.getSeconds()
  }
  for (let k in o) {
    if (new RegExp(`(${k})`).test(fmt)) {
      let str = o[k] + ''
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length === 1 ? str : ('00' + str).substr(str.length)
      )
    }
  }
  return fmt
})
Vue.filter('formatPrice', vaule => {
  if (!vaule) return vaule
  // 千分位分隔符
  return Number(vaule).toLocaleString()
})
Vue.filter('getSuffix', value => {
  let type = 7
  if (/(.step|.stl|.stp|.prt)$/.test(value)) {
    type = 1
  } else if (/(.jpg|.png|.webp|.jpeg)$/.test(value)) {
    type = 6
  } else if (/.pdf$/i.test(value)) {
    type = 2
  } else if (/(.xlsx|.xls)$/i.test(value)) {
    type = 3
  } else if (/.ppt$/i.test(value)) {
    type = 5
  }
  return type
})
//格式化转义符
Vue.filter('escape', str => {
  return str
    .replace(/&lt;/g, '<')
    .replace(/&gt;/g, '>')
    .replace(/&amp;/g, '&')
    .replace(/&quot;/g, '"')
    .replace(/&#39;/g, "'")
})
